<template>
    <div>
        <el-card>
            <div class="update_head">系统更新</div>
            <el-checkbox-group v-model="material">
                <el-checkbox v-for="(item, index) in checkboxData" :key="index" :label="item.key" name="type">{{
                    item.value }}</el-checkbox>
            </el-checkbox-group>
            <el-button class="submit-btns" type="primary" @click="submit"
                v-if="hasPerm(['system.clear_cache'])">保存</el-button>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            material: [],
            checkboxData: [
                {
                    key: 'data_clear',
                    value: '数据缓存'
                }, {
                    key: 'file_clear',
                    value: '临时图片'
                }
            ]
        }
    },
    methods: {
        submit() {
            const _data = {
                'data_clear': 0,
                'file_clear': 0
            }
            this.material.forEach(item => {
                _data[item] = 1
            })
            console.log(_data);
            this.$put(this.$apis.clearCache, _data).then(res => {
                if (res.code == 200) {
                    this.$message.success('清除成功')
                    this.material = []
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.update_head {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding-bottom: 10px;
    border-bottom: solid 1px #ECEEEF;
    margin-bottom: 16px;
}

.submit-btns {
    margin-top: 20px;
}
</style>